import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

import axios from "../../api/axios";
import { EMAIL_LOGIN_URL, ROLES } from "../../helpers/constants";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist"))
  );

  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/discover";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        EMAIL_LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (res.data.statusCode == 200) {
        const accessToken = res.data?.token;
        const roles = res.data?.data?.roles;
        const user = res.data?.data?.user;
        setAuth({ email, password, roles, accessToken, userId: user?._id });
        window.localStorage.setItem("roles", roles); /// TODO: Remove (for security reasons)
        setEmail("");
        setPassword("");
        toast.success("Login successful");

        if (roles.includes(ROLES.ADMIN)) {
          navigate("/admin", { replace: true });
        } else {
          navigate('/discover', {replace: true})
        }
      } else {
        if (res?.data?.message) {
          const passwordErr = res?.data?.error?.response?.errors?.password;
          const emailErr = res?.data?.error?.response?.errors?.email;

          toast.error(res?.data?.message);
          toast.warn(passwordErr);
          toast.warn(emailErr ? "Email " + emailErr : undefined); /// TODO: Remove this, for security reasons
        } else {
          toast.error("Failed to log in");
        }
        console.log(res);
      }
    } catch (e) {
      toast.error(e?.response?.message);
      toast.error(e?.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  useEffect(() => {
    if (auth?.accessToken) {
      toast.warn("Already logged in");
      navigate("");
    }
  }, []);

  return (
    <div className="bg-green-200 w-screen h-screen">
      <div class="flex flex-col items-center px-6 sm:px-2 py-8 mx-auto w-[35rem] min-h-[80vh] sm:w-full">
        <div class="w-full bg-primary-200 rounded-lg shadow">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <p class="text-xl font-bold text-gray-900 md:text-2xl">Log In</p>
            <p className="text-sm">Log in to the world of home grown tunes</p>
            <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  for="matricNo"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button
                disabled={loading}
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-primary-300"
              >
                {loading ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-6 h-6 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  ""
                )}
                {loading ? "Log in..." : "Log in"}
              </button>
              <div class="flex items-center">
                <input
                  id="persist"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  onChange={togglePersist}
                  checked={persist}
                />
                <label
                  for="link-checkbox"
                  class="ml-2 text-sm font-medium text-gray-400"
                >
                  Trust this device
                </label>
              </div>
              <p class="text-sm font-light text-gray-500">
                Dont have an account?{" "}
                <a
                  href="#"
                  class="font-medium text-primary-600 hover:underline"
                >
                  <Link to="/register">Register here</Link>
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
