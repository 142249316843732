import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../components/Admin/Sidebar";
import Player from "../../components/AudioPlayer/Player";
import DisplayDuration from "../../components/DisplayDuration";
import SongAvatar from "../../components/SongAvatar";
import Loader from "../../components/Spinners/Loader/Loader";
import NowPlaying from "../../components/Visualizer/NowPlaying";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AdminSongs = () => {
  const [songs, setSongs] = useState(null);
  const [display, setDisplay] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [trackIndex, setTrackIndex] = useState(0);

  const activateDeactivateSong = async (song) => {
    try {
      const res = await axiosPrivate.patch(`admin/${song._id}/adsong`);
      if (res.data.statusCode == 200) {
        toast.success(res.data?.message);
        getSongs();
      } else {
        toast.success(res.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };

  const getSongs = async () => {
    try {
      const res = await axiosPrivate.get("admin/songs");
      setSongs(res.data.data.songs);
    } catch (error) {
      console.log(error);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    getSongs();
  }, []);

  return (
    <div className="bg-gray-400 flex flex-col items-start p-4 w-full min-h-full">
      {songs ? (
        <>
          {songs.length !== 0 ? (
            <>
              <div class="bg-gray-300 w-full rounded">
                <table class="w-full text-sm text-left text-gray-500 gap-2">
                  {songs?.map((song, index) => (
                    <tr
                      onClick={() => {
                        setDisplay(true);
                        setTrackIndex(1);
                      }}
                      class="border-b hover:bg-gray-400"
                    >
                      <th
                        scope="row"
                        class=" flex items-center px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <SongAvatar style="h-[48px] w-[35px] border-2" />
                        <div className="ml-4">{song.title}</div>
                      </th>
                      <td class="px-6 py-4">{song?.artistId?.stageName}</td>
                      <DisplayDuration audioFile={songs[index].dataUrl} />
                      <td>
                        <button className={`${song.isActive? 'bg-black text-gray-300': 'bg-white text-gray-500'} px-3 py-2 rounded-2xl`} onClick={() => activateDeactivateSong(song)}>
                          {song.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              {/* <Player
                tracks={songs}
                trackIndex={trackIndex}
                setTrackIndex={setTrackIndex}
                display={display}
              /> */}
            </>
          ) : (
            <div className="italic w-full text-center">
              Cannot find any song in the database...
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AdminSidebar(AdminSongs);
