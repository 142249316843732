import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const Home = () => {
  const navigate = useNavigate()
  
  return (
    <div className="flex justify-center items-center min-h-full w-full">
      <button onClick={() => navigate('/songs')} className="flex gap-4 shadow-md rounded-md px-[30px] py-[15px] bg-white">
        <div>Listen</div>
        <svg
          class="w-6 h-6 text-gray-800"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 16"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 11.5V1s3 1 3 4m-7-3H1m9 4H1m4 4H1m13 2.4c0 1.325-1.343 2.4-3 2.4s-3-1.075-3-2.4S9.343 10 11 10s3 1.075 3 2.4Z"
          />
        </svg>
      </button>
    </div>
  );
};

export default Sidebar(Home);
