import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import PlayIconEmpty from "../components/AudioPlayer/PlayIconEmpty";
import LinkIcon from "../components/icons/LinkIcon";
import Sidebar from "../components/Sidebar";
import SongAvatar from "../components/SongAvatar";
import Loader from "../components/Spinners/Loader/Loader";

const Artists = () => {
  const navigate = useNavigate();
  const [artists, setArtists] = useState(null);
  const [loading, setLoading] = useState(false);

  const getArtists = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/artist`);
      if (res.data.statusCode === 200) {
        setArtists(res.data?.data?.artists);
      } else {
        toast.error(res.data?.message);
      }
    } catch (error) {
      console.error(error);
      //   navigate("/login", { state: { from: location }, replace: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArtists();
  }, []);

  return (
    <div className="flex w-full mt-10 ml-5">
      <div className="flex flex-wrap gap-3 mx-6">
        {artists !== null ? (
          <>
            {artists?.map((artist) => (
              <div className="h-[280px] w-[210px] rounded-lg bg-black flex flex-col items-center">
                <div className="flex h-[80%] flex-col items-center justify-center mb-3">
                  <div className="p-3 flex justify-center items-center mb-2">
                    {artist?.profileImage ? (
                      <img
                        src={artist?.profileImage}
                        className="w-[140px] h-[140px] rounded-full"
                      />
                    ) : (
                      <SongAvatar styles="w-[140px] h-[140px] border-2 bg-green-300" />
                    )}
                  </div>
                  <div className="text-gray-400">{artist?.stageName}</div>
                </div>

                <Link to={`/artist/${artist?._id}`}>
                  <LinkIcon styles="text-green-600" />
                </Link>
              </div>
            ))}
          </>
        ) : (
          <div className="h-full flex justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar(Artists);
