import React from 'react'
import "./NowPlaying.css"

const NowPlaying = () => {
  return (
    <div class="">
      <div class="lds-facebook"><div></div><div></div><div></div></div>
    </div>
  )
}

export default NowPlaying