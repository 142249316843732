import React from "react";
import PlayIcon from "./AudioPlayer/PlayIcon";
import PlayIconEmpty from "./AudioPlayer/PlayIconEmpty";
import SongAvatar from "./SongAvatar";

const DisplaySearchResult = ({ display, loadingSearch, searchResult }) => {
  return (
    <div
      className={`${display} w-full rounded-lg bg-gray-100 absolute items-center justify-center p-4`}
    >
      {!loadingSearch ? (
        <div className="w-full">
          <>
            {searchResult?.length > 0 ? (
              <table className="w-full text-sm text-left text-gray-500 gap-2 rounded-lg">
                {searchResult.map((song) => (
                  <tr className="border rounded-lg">
                    <th
                      scope="row"
                      class=" flex items-center px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                    >
                      <SongAvatar
                        style="h-[38px] w-[25px] border-2"
                        coverUrl={song?.coverUrl}
                      />
                      <div className="ml-4">{song.title}</div>
                    </th>
                    <td class="px-6 py-4">{song?.artistId?.stageName}</td>
                    <td>
                      <button>
                        <PlayIconEmpty styles="w-5 h-5 text-green-600" />
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <div>No search result found</div>
            )}
          </>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default DisplaySearchResult;
