import React from "react";
import "./style.css"

const SeqBallSpinner = () => {
  return (
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SeqBallSpinner;
