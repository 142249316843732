import { BsMusicNoteBeamed } from "react-icons/bs";

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  return (
    <div>
      <audio
        src={currentTrack?.dataUrl}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
      <div className="flex w-[20vw] items-center pl-6">
        <div className="border-green-200 rounded-full w-[50px] h-[50px] flex justify-center items-center bg-green-400">
          {currentTrack?.coverUrl ? (
            <img src={currentTrack?.coverUrl} className="w-full h-full rounded-full" alt="audio avatar" />
          ) : (
            <div className="">
              <span className="">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div>
        <div className="ml-4">
          <p className="text-sm">{currentTrack?.title}</p>
          <p className="text-[10px]">{currentTrack?.artistId?.stageName}</p>
        </div>
      </div>
    </div>
  );
};
export default DisplayTrack;
