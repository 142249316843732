import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DisplayDuration from "../components/DisplayDuration";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SeqBallSpinner from "../components/Spinners/SeqBall";
import { toast } from "react-toastify";
import { BsMusicNoteBeamed } from "react-icons/bs";
import LinkIcon from "../components/icons/LinkIcon";
import PlayIconEmpty from "../components/AudioPlayer/PlayIconEmpty";
import Player from "../components/AudioPlayer/Player";

const TopCharts = () => {
  const navigate = useNavigate();
  const [topSongs, setTopSongs] = useState(null);
  const [topArtists, setTopArtists] = useState(null);
  const [display, setDisplay] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getTopSongs = async () => {
      try {
        const res = await axiosPrivate.get(
          "/song/top",
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setTopSongs(res.data.data);
        console.log("songs: ", res.data.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    const getTopArtists = async () => {
      try {
        const res = await axiosPrivate.get(
          "/artist/top",
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setTopArtists(res.data.data);
        console.log("artists: ", res.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    const getChartData = async () => {
      await getTopSongs();
      await getTopArtists();
    };

    getChartData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-full w-full pb-10">
      <div className="bg-black text-green-500 rounded-3xl w-[99%] h-[400px] mt-4 mx-1 flex justify-center items-center text-5xl p-20 italic">
        Check out the trending top songs, top artists, top playlists and more
        ...
      </div>

      <div className="flex gap-6 mt-8 ml-4">
        {/* Popular tracks */}
        <div className="flex flex-col w-[70%] gap-2">
          <div className="flex justify-between pr-8">
            <div className="text-black font-bold text-lg">Popular Songs</div>
            <button
              className="text-[12px] text-gray-600 italic hover:underline"
              onClick={() => navigate("/songs")}
            >
              View all
            </button>
          </div>
          <div className="flex flex-col gap-4 h-[300px]">
            {topSongs !== null ? (
              <>
                {topSongs?.map((song, index) => (
                  <div className="bg-black flex justify-between items-center rounded w-full px-4 py-1 h-[50px]">
                    <div className="flex items-center gap-3">
                      <div className="border-green-200 rounded-full w-[40px] h-[40px] flex justify-center items-center bg-green-900">
                        {song?.coverUrl ? (
                          <img
                            src={song?.coverUrl}
                            className="w-full h-full rounded-full"
                          />
                        ) : (
                          <div className="">
                            <span className="">
                              <BsMusicNoteBeamed color="white" />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="text-gray-300">{song.title}</div>
                    </div>
                    <div className="flex text-gray-400 text-[12px]">
                      {song.artistId?.stageName}
                    </div>
                    <div className="flex text-[12px]">
                      <DisplayDuration
                        audioFile={song?.dataUrl}
                        styles="text-gray-400"
                      />
                    </div>
                    <button
                      onClick={() => {
                        setDisplay(true);
                        setTrackIndex(index)
                      }}
                    >
                      <PlayIconEmpty styles="w-5 h-5 text-green-600" />
                    </button>
                  </div>
                ))}
                <Player
                  display={display}
                  tracks={topSongs}
                  trackIndex={trackIndex}
                  setTrackIndex={setTrackIndex}
                />
              </>
            ) : (
              <div className="h-full flex justify-center items-center">
                <SeqBallSpinner />
              </div>
            )}
          </div>
        </div>

        {/* Popular Artists */}
        <div className="flex flex-col gap-2 w-[25%]">
          <div className="flex justify-between pr-8">
            <div className="text-black font-bold text-lg">Popular Artists</div>
            <button
              className="text-[12px] text-gray-600 italic hover:underline"
              onClick={() => navigate("/artists")}
            >
              View all
            </button>
          </div>
          <div className="flex flex-col gap-4 h-[300px]">
            {topArtists !== null ? (
              <>
                {topArtists?.map((artist) => (
                  <div className="bg-black flex rounded w-full p-2 h-[116px]">
                    <img
                      className="h-full rounded w-[35%]"
                      src={
                        artist?.profileImage
                          ? artist?.profileImage
                          : "https://image.lexica.art/full_webp/eb60042e-bc7f-4d2e-871d-bf73045b76bd"
                      }
                    />
                    <div className="flex w-[65%]">
                      <div className="flex flex-col my-4 ml-4 justify-between">
                        <div className="text-sm text-gray-300 mb-3">
                          {artist?.stageName}
                        </div>
                        <div className="text-[10px] text-green-100 italic">
                          Songs: {artist?.songsCount}
                        </div>
                        <div className="text-[10px] text-green-100 italic">
                          Followers: {artist?.followersCount}
                        </div>
                      </div>
                      <div className="flex w-[45%] justify-center items-center">
                        <Link to={`/artist/${artist?.id}`}>
                          <LinkIcon styles="text-green-600" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="h-full flex justify-center items-center">
                <SeqBallSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Popular Playlists */}
      {/* <div className="flex flex-col gap-2 mt-8 ml-4">
        <div className="flex justify-between pr-8">
          <div className="text-black font-bold text-lg">Popular Playlists</div>
          <button
            className="text-[12px] text-gray-600 italic hover:underline"
            onClick={() => navigate("/artists")}
          >
            View all
          </button>
        </div>
        <div className="flex gap-4">
          {[1, 2, 3, 4, 5].map((track) => (
            <div className="bg-black rounded w-[250px] px-4 py-1 h-[200px]">
              {}
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Sidebar(TopCharts);
