import axios from "../api/axios";
import useAuth from "./useAuth";

const REFRESH_TOKEN_URL = "/auth/refresh";

const useRefreshToken = () => {
  const { setAuth, auth } = useAuth();

  const refresh = async () => {
    const response = await axios.post(
      REFRESH_TOKEN_URL,
      {},
      { withCredentials: true }
    );
    setAuth((prev) => {
      return {
        ...prev,
        userId: response.data.data?.userId,
        roles: response.data.data?.roles,
        accessToken: response.data.token,
      };
    });
    return response.data.token;
  };

  return refresh;
};

export default useRefreshToken;
