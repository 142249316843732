import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Player from "../components/AudioPlayer/Player";
import DisplayDuration from "../components/DisplayDuration";
import Loader from "../components/Spinners/Loader/Loader";
import SongAvatar from "../components/SongAvatar";
import NowPlaying from "../components/Visualizer/NowPlaying";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SeqBallSpinner from "../components/Spinners/SeqBall";
import { BsMusicNoteBeamed } from "react-icons/bs";
import PlayIcon from "../components/AudioPlayer/PlayIcon";
import LinkIcon from "../components/icons/LinkIcon";
import PlayIconEmpty from "../components/AudioPlayer/PlayIconEmpty";

const Discover = () => {
  const axiosPrivate = useAxiosPrivate();
  const [discoveredSongs, setDiscoveredSongs] = useState(null);
  const [display, setDisplay] = useState(false)
  const [trackIndex, setTrackIndex] = useState(0)

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const discoverSongs = async () => {
      try {
        const res = await axiosPrivate.get(
          "/song/discovery",
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setDiscoveredSongs(res.data?.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    discoverSongs();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-full w-full pb-10 mb-10">
      {/* <p className="text-[40px] p-6 pb-12 bg-gray-100">Discover local artists, songs, and more...</p> */}
      <div className="bg-black text-green-500 rounded-3xl w-[99%] h-[400px] mt-4 mx-1 mb-10 flex justify-center items-center text-5xl p-20 italic">
        Gallery of songs on the Homegrown tunes platform
      </div>

      <div className="ml-6 my-6 underline text-lg text-center w-full">
        Latest songs
      </div>
      <div className="flex flex-wrap justify-center gap-3 mx-6">
        {discoveredSongs !== null ? (
          <>
            {discoveredSongs?.map((song, index) => (
              <div className={`${trackIndex === index? 'border-4': ''} h-[280px] w-[210px] rounded-lg bg-black flex flex-col items-center border-yellow-500`}>
                <div className="flex h-[80%] flex-col items-center justify-center mb-3">
                  <div className="p-3 flex justify-center items-center mb-2">
                    {song?.coverUrl ? (
                      <img
                        src={song?.coverUrl}
                        className="w-[140px] h-[140px] rounded-full"
                      />
                    ) : (
                      <SongAvatar styles="w-[140px] h-[140px] border-2 bg-green-300" />
                    )}
                  </div>
                  <div className="text-gray-300 w-[80%] text-center">{song?.title}</div>
                  <div className="text-gray-500 text-[12px]">
                    {song?.artistId?.stageName}
                  </div>
                </div>

                <button onClick={() => {
                  setDisplay(true);
                  setTrackIndex(index);
                }}>
                  <PlayIconEmpty styles="w-5 h-5 text-green-600" />
                </button>
                {/* <td class="">{trackIndex === index && <NowPlaying/>}</td> */}
              </div>
            ))}
            <Player
              display={display}
              trackIndex={trackIndex}
              setTrackIndex={setTrackIndex}
              tracks={discoveredSongs}
            />
          </>
        ) : (
          <div className="h-full flex justify-center items-center">
            <SeqBallSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar(Discover);
