import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLogout from "../../hooks/useLogout";
import SeqBallSpinner from "../Spinners/SeqBall";

const AdminSidebar = (Component) =>
  function HOC() {
    const [profile, setProfile] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);

    const navigate = useNavigate();
    const logout = useLogout();

    const axiosPrivate = useAxiosPrivate();

    const signOut = async () => {
      await logout();
      navigate("/");
    };

    useEffect(() => {
      const getProfile = async () => {
        try {
          setLoadingProfile(true);
          const res = await axiosPrivate.get("/auth/me");
          setProfile(res.data);
        } catch (error) {
          console.log(error);
          toast.error(error);
        } finally {
          setLoadingProfile(false);
        }
      };

      getProfile();
    }, []);

    return (
      <div className="w-full min-h-[100vh] flex relative">
        <div className="w-[20%] h-screen bg-black text-white fixed top-0 left-0 px-8 pt-4">
          {/* Profile */}
          <div className="h-8 mb-16 flex items-center">
            {!loadingProfile ? (
              <p className="text-yellow-500">
                {profile?.firstName} {profile?.lastName}
              </p>
            ) : (
              <SeqBallSpinner />
            )}
          </div>

          {/* Browse */}
          <p className="text-xl mb-6 text-gray-500">Browse</p>
          <div className="mb-8 flex flex-col gap-3">
            <div onClick={() => navigate("/admin")} className="cursor-pointer">
              <span className=""></span>
              <span className="">Stats</span>
            </div>
          </div>
          <div className="mb-8 flex flex-col gap-3">
            <div
              onClick={() => navigate("/admin/songs")}
              className="cursor-pointer"
            >
              <span className=""></span>
              <span className="">Songs</span>
            </div>
          </div>
          <div className="mb-8 flex flex-col gap-3">
            <div
              onClick={() => navigate("/admin/artists")}
              className="cursor-pointer"
            >
              <span className=""></span>
              <span className="">Artists</span>
            </div>
          </div>
          <button onClick={signOut} className="w-full mt-32 text-left text-red-600">
            Logout
          </button>
        </div>
        <div className="w-full flex justify-end">
          <div className="bg-gray-400 w-[80%] flex justify-start items-start">
            <Component />
          </div>
        </div>
      </div>
    );
  };

export default AdminSidebar;
