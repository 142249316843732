import React from "react";

const PlayIconEmpty = ({styles}) => {
  return (
    <div>
      <svg
        class={`${styles} w-6 h-6 text-gray-800`}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1.984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L2.506 1.139A1 1 0 0 0 1 1.984Z"
        />
      </svg>
    </div>
  );
};

export default PlayIconEmpty;
