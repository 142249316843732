import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/Admin/Sidebar";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/Spinners/Loader/Loader";
import LinkIcon from "../../components/icons/LinkIcon";
import { Link } from "react-router-dom";

const Stats = () => {
  const axiosPrivate = useAxiosPrivate();
  const [artists, setArtists] = useState(null);
  const [songs, setSongs] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSongs = async () => {
      try {
        const res = await axiosPrivate.get(
          "/admin/songs",
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setSongs(res.data?.data?.songs);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    const getArtists = async () => {
      try {
        const res = await axiosPrivate.get(
          "/admin/artists",
          {},
          {
            signal: controller.signal,
          }
        );
        console.log(res);
        isMounted && setArtists(res.data?.data?.artists);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    getSongs();
    getArtists();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-full w-full pb-10 mb-10">
      {songs !== null || artists !== null ? (
        <div className="w-full flex justify-around p-5">
          <div className="rounded-lg w-[40%] h-[200px] bg-yellow-300 p-3 flex flex-col justify-between">
            <div className="text-lg">
              Total songs on the Homegrown Tunes Platform:
            </div>
            <div className="flex justify-between items-center px-5">
              <svg
                class="w-16 h-16 text-gray-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 16"
              >
                <path d="M14.316.051A1 1 0 0 0 13 1v8.473A4.49 4.49 0 0 0 11 9c-2.206 0-4 1.525-4 3.4s1.794 3.4 4 3.4 4-1.526 4-3.4a2.945 2.945 0 0 0-.067-.566c.041-.107.064-.22.067-.334V2.763A2.974 2.974 0 0 1 16 5a1 1 0 0 0 2 0C18 1.322 14.467.1 14.316.051ZM10 3H1a1 1 0 0 1 0-2h9a1 1 0 1 1 0 2Z" />
                <path d="M10 7H1a1 1 0 0 1 0-2h9a1 1 0 1 1 0 2Zm-5 4H1a1 1 0 0 1 0-2h4a1 1 0 1 1 0 2Z" />
              </svg>
              <div className="flex items-center gap-2">
                <Link to="songs">
                  <LinkIcon />
                </Link>
                <div className="text-7xl">{songs?.length}</div>
              </div>
            </div>
          </div>

          <div className="rounded-lg w-[40%] h-[200px] bg-orange-300 p-3 flex flex-col justify-between">
            <div className="text-lg">
              Total artists on the Homegrown Tunes Platform:
            </div>
            <div className="flex justify-between items-center px-5">
              <svg
                class="w-16 h-16 text-gray-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 14 18"
              >
                <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
              </svg>
              <div className="flex items-center gap-2">
                <Link to="artists">
                  <LinkIcon />
                </Link>
                <div className="text-7xl">{artists?.length}</div>
              </div>
            </div>
          </div>

          {/* <div className="rounded-lg w-[40%] h-[200px] bg-orange-300 p-3 flex flex-col justify-between">
            <div className="text-lg">
              Total artists on the Homegrown Tunes Platform:
            </div>
            <div className="flex w-full justify-end gap-4 items-center pr-6">
              <Link to="artists">
                <LinkIcon />
              </Link>
              <div className="text-7xl">{artists?.length}</div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="h-full flex justify-center items-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AdminSidebar(Stats);
