import React, { useEffect, useRef, useState } from "react";
import { formatTime } from "../helpers/format-audio-time";

const DisplayDuration = ({ audioFile, styles }) => {
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      const handleLoadedMetadata = () => {
        const audioDuration = audioRef?.current?.duration || 0;
        setDuration(audioDuration);
      };

      if (audioRef.current.addEventListener !== null)
        audioRef.current.addEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );

      return () => {
        if (audioRef?.current?.removeEventListener) {
          audioRef.current.removeEventListener(
            "loadedmetadata",
            handleLoadedMetadata
          );
        }
      };
    }
  }, []);

  return (
    <div className={`${styles}`}>
      <audio ref={audioRef} src={audioFile} />
      {formatTime(duration.toFixed(2))}
    </div>
  );
};

export default DisplayDuration;
