import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import useAuth from "./useAuth";

const LOGOUT_URL = "/auth/logout";

const useLogout = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate()

  const logout = async () => {
    try {
      const response = await axios.post(
        LOGOUT_URL,
        {},
        {
          withCredentials: true,
        }
      );

      if (response.data?.statusCode === 200) {
        setAuth({});
        window.localStorage.setItem('roles', null)
        toast.success(response.data?.message)
        navigate('/')
      } else {
        toast.error('Failed to log out')
      }
    } catch (error) {
      toast.error(error?.message)
      console.error(error);
    }
  };
  return logout;
};

export default useLogout;
