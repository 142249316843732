import React from "react";
import { Link } from "react-router-dom";
import  bgVisualizer from "../files/images/bg_visualizer.gif"
import { ROLES } from "../helpers/constants";

const Landing = () => {
  const roles = window.localStorage.getItem('roles')

  return (
    <div className="w-screen flex bg-transparent text-green-100 relative">
      <div className="w-[60%] px-[2rem] py-[4rem]">
        <h1 className="text-[3.2rem] mb-8 text-green-400">Welcome to Homegrown Tunes</h1>
        <p className="text-[1.5rem] mb-8 text-green-200">Discover and Support Local Music</p>
        <p className="italic mb-16">
          Homegrown Tunes is your window to the vibrant world of local music.
          Connect with local artists, find live performances, and enjoy original
          compositions that reflect your community's heart and soul.
        </p>

        <p className="mb-4 text-[1.5rem]">What We Offer:</p>
        <ul className="mb-16">
          <li className="mb-2">
            <span className="font-bold underline italic">
              Discover Local Artists:{" "}
            </span>
            Explore a diverse range of talented local musicians.
          </li>
          <li className="mb-2">
            <span className="font-bold underline italic">Stay Updated: </span>
            Find upcoming local gigs and concerts.
          </li>
          <li className="mb-2">
            <span className="font-bold underline italic">
              Support Local Talents:{" "}
            </span>
            Join a thriving community of music lovers.
          </li>
        </ul>

        <p className="text-[1.5rem] mb-4">Join the Homegrown Movement</p>
        <ul className="mb-8">
          <li>
            <span className="italic">Connect: </span>Discover and connect with
            local music enthusiasts.
          </li>
          <li>
            <span className="italic">Celebrate Together: </span>Attend live
            events and support local talent.
          </li>
        </ul>

        <p className="mb-8">
          Start your musical journey and become a part of the Homegrown Tunes
          family
        </p>

        {/* {isAuthenticated ? (
          <Link to="/home">
            <button className="bg-white text-black rounded-md px-[10px] py-[10px]">
              Explore
            </button>
          </Link>
        ) : (
          <div className="flex gap-2">
            <Link to="/register">
              <button className="bg-white text-black rounded-md px-[10px] py-[10px]">
                Register
              </button>
            </Link>
            <Link to="/login">
              <button className="bg-white text-black rounded-md px-[10px] py-[10px]">
                Log in
              </button>
            </Link>
          </div>
        )} */}
        <Link to={roles?.includes(ROLES.ADMIN) ? "/admin" : "/discover"}>
          <button className="bg-white text-black rounded-md px-[10px] py-[10px]">
            Explore
          </button>
        </Link>
      </div>  
      <img src={bgVisualizer} className="absolute h-full w-full -z-10 top0 left-0"/>
    </div>
  );
};

export default Landing;
