import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";

import { Login, Register } from "./pages/Auth";
import Home from "./pages/Home";

import { ROLES } from "./helpers/constants";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import Landing from "./pages/Landing";
import AdminHome from "./pages/AdminHome";
import ArtistHome from "./pages/ArtistHome";
import PersistLogin from "./components/PersistLogin";
import Create from "./pages/artist/Create";
import Songs from "./pages/Songs";
import Join from "./pages/artist/Join";
import Discover from "./pages/Discover";
import TopCharts from "./pages/TopCharts";
import Artists from "./pages/Artists";
import ArtistSongs from "./pages/artist/Songs";
import ArtistProfile from "./pages/artist/Profile";
import Artist from "./pages/Artist";
import PlaySong from "./pages/PlaySong";
import Stats from "./pages/Admin/Stats";
import AdminSongs from "./pages/Admin/Songs";
import AdminArtists from "./pages/Admin/Artists";
import RequireSubscribe from "./pages/Subscribe";

// Main parent component
export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public routes */}
          <Route path="" element={<Landing />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          {/* Protected routes */}
          <Route element={<PersistLogin />}>
            {/* Shared, General */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.GUEST, ROLES.ARTIST, ROLES.ADMIN]}
                />
              }
            >
              <Route element={<RequireSubscribe />}>
                <Route path="/discover" element={<Discover />} />
                <Route path="/top-charts" element={<TopCharts />} />
                <Route path="/artists" element={<Artists />} />
                <Route path="/songs" element={<Songs />} />
                <Route path="/join" element={<Join />} />
                <Route path="/artist/:artistId" element={<Artist />} />
                <Route path="/play/:songId" element={<PlaySong />} />
              </Route>
            </Route>

            {/* Artists */}
            <Route element={<RequireAuth allowedRoles={[ROLES.ARTIST]} />}>
              <Route path="artist">
                <Route path="" element={<ArtistHome />} />
                <Route path="create" element={<Create />} />
                <Route path="songs" element={<ArtistSongs />} />
                <Route path="profile" element={<ArtistProfile />} />
              </Route>
            </Route>

            {/* Admin */}
            <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
              <Route path="admin">
                <Route path="" element={<Stats />} />
                <Route path="songs" element={<AdminSongs />} />
                <Route path="artists" element={<AdminArtists />} />
              </Route>
            </Route>
          </Route>

          {/* Catch All */}
          <Route
            path="*"
            element={
              <div className="w-full h-screen flex justify-center items-center bg-black text-white">
                404 - Page not found
              </div>
            }
          />
        </Route>
      </Routes>
      <ToastContainer position="bottom-center" />
    </>
  );
}
