import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROLES } from "../helpers/constants";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";
import DisplaySearchResult from "./DisplaySearchResult";
import SmLoader from "./Spinners/SmLoader";
import SeqBallSpinner from "./Spinners/SeqBall";

const Sidebar = (Component) =>
  function HOC() {
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [profile, setProfile] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [songsResult, setSongsResult] = useState([]);

    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth();

    const axiosPrivate = useAxiosPrivate();

    const signOut = async () => {
      await logout();
      navigate("/");
    };

    const create = async () => {
      setLoading(true);
      if (auth?.roles?.includes(ROLES.ARTIST)) {
        try {
          const res = await axiosPrivate.get("/artist/me");

          if (res.data?.data?.profile?.stageName) {
            navigate("/artist/create");
          } else {
            navigate("/join");
          }
        } catch (error) {
          console.error(error);
          toast.error(error?.message);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/join");
        setLoading(false);
      }
    };

    const search = async (query) => {
      setLoadingSearch(true);
      try {
        const songsRes = await axiosPrivate.get(`/song/search?q=${query}`);
        const artistsRes = await axiosPrivate.get(`/artist/search?q=${query}`);

        if (songsRes.data?.statusCode == 200) {
          const result = songsRes.data?.data?.searchResult;
          setSongsResult(result);
        } else {
          toast.error("Failed to search");
        }
      } catch (error) {
        console.error(error);
        toast.error(error?.message);
      } finally {
        setLoadingSearch(false);
      }
    };

    const handleSearchQueryChange = (e) => {
      e.preventDefault();
      const q = e.target.value;
      setSearchQuery(q);
      setTimeout(() => search(q), 2000);
    };

    useEffect(() => {
      const getProfile = async () => {
        try {
          setLoadingProfile(true);
          const res = await axiosPrivate.get("/auth/me");
          setProfile(res.data);
        } catch (error) {
          console.log(error);
          toast.error(error);
        } finally {
          setLoadingProfile(false);
        }
      };

      getProfile();
    }, []);

    return (
      <div className="w-screen min-h-[100vh] flex relative bg-black">
        {!loading ? (
          <>
            <div className="w-[20%] h-screen bg-black text-white fixed top-0 left-0 px-8 pt-4">
              {/* Profile */}
              <div className="h-8 mb-16 flex items-center">
                {!loadingProfile ? (
                  <p className="text-green-500">
                    {profile?.firstName} {profile?.lastName}
                  </p>
                ) : (
                  <SeqBallSpinner />
                )}
              </div>

              {/* Browse */}
              <p className="text-xl mb-5 text-gray-500">Browse</p>
              <div className="mb-16 flex flex-col gap-3">
                <div
                  onClick={() => navigate("/discover")}
                  className="cursor-pointer"
                >
                  <span className=""></span>
                  <span className="">Discover</span>
                </div>
                <div
                  onClick={() => navigate("/songs")}
                  className="cursor-pointer"
                >
                  <span className=""></span>
                  <span className="">Songs</span>
                </div>
                <div
                  onClick={() => navigate("/top-charts")}
                  className="cursor-pointer"
                >
                  <span className=""></span>
                  <span className="">Top charts</span>
                </div>
                <div
                  onClick={() => navigate("/artists")}
                  className="cursor-pointer"
                >
                  <span className=""></span>
                  <span className="">Artists</span>
                </div>
              </div>

              {/* Playlist */}
              <p className="text-xl mb-5 text-gray-500">Playlist</p>
              <div className="flex flex-col gap-3 mb-32">
                <div className="cursor-pointer">
                  <span className=""></span>
                  <span className="">Favourites</span>
                </div>
                <div className="cursor-pointer">
                  <span className=""></span>
                  <span className="">Recent</span>
                </div>
              </div>

              {/* Create  */}
              <button
                onClick={create}
                className="text-black bg-white w-full py-3 rounded-2xl mb-24"
              >
                Get Creative
              </button>

              <button onClick={signOut} className="w-full">
                Logout
              </button>
            </div>
          </>
        ) : (
          <div className=""></div>
        )}

        <div className="w-full flex justify-end">
          <div className="bg-green-300 w-[80%] flex flex-col justify-start items-center">
            {/* Search section */}
            <div class="flex items-center w-[50%] my-5">
              <label for="simple-search" class="sr-only">
                Search
              </label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-green-500 block w-full ps-10 p-2.5"
                  placeholder="Search for songs..."
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  required
                />
              </div>
              {/* <button
                type="button"
                class="p-2.5 ms-2 text-sm font-medium text-green-400 bg-black rounded-lg border border-green-700 hover:border-white focus:ring-4 focus:outline-none focus:ring-green-300"
              >
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span class="sr-only">Search</span>
              </button> */}
            </div>

            <div className="w-[50%] relative">
              <DisplaySearchResult
                display={searchQuery ? "flex" : "hidden"}
                searchResult={songsResult}
                loadingSearch={loadingSearch}
              />
            </div>
            <Component />
          </div>
        </div>
      </div>
    );
  };

export default Sidebar;
