import React, { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Spinners/Loader/Loader";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const subscriptionAmount = process.env.REACT_APP_SUBSCRIPTION_AMOUNT;
const amount = Number(subscriptionAmount) * Number(100); // Convert to Kobo equivalent;
const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

const RequireSubscribe = () => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [profile, setProfile] = useState(null);

  const componentProps = {
    email: profile?.email,
    amount,
    metadata: {
      name: `${profile?.firstName} ${profile?.lastName}`,
    },
    publicKey,
    text: "Subscribe",
    reference: `hgt-ref-${Math.floor(
      Math.random() * 1_000_000_000_000_000 + 1
    ).toString("16")}`,
    onSuccess: (res) => {
      const payment_ref = res?.reference;

      const verify = async () => {
        if (payment_ref) {
          try {
            const res = await axiosPrivate.get(
              `/users/verify_payment/${payment_ref}`
            );

            console.log(res.data);
            if (res.data?.statusCode === 200) {
              toast.success(res.data?.message);
              window.location.reload();
            } else {
              toast.error(res.data?.message);
            }
          } catch (error) {
            toast.error(error?.message);
          }
        } else {
          alert("Could not complete payment");
        }
      };

      verify();
    },
    onClose: () =>
      alert(
        "You have to complete your subscription in order to explore the Homegrown Tunes platform"
      ),
  };

  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoadingProfile(true);
        const res = await axiosPrivate.get("/auth/me");
        setProfile(res.data);
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setLoadingProfile(false);
      }
    };

    getProfile();
  }, []);

  return (
    <>
      {!loadingProfile ? (
        <>
          {" "}
          {profile?.hasActiveSubscription ? (
            <Outlet />
          ) : (
            <div className="w-screen min-h-screen flex items-center justify-center bg-black">
              {/* New user */}
              <div className="w-[60%] h-[600px] p-16 border shadow-lg flex flex-col justify-between items-center bg-gray-200 rounded-lg">
                {profile?.subscriptions == 0 && (
                  <div className="flex flex-col w-full items-center text-center">
                    <div className="text-[50px] mb-8">Hi there 👋,</div>
                    <div className="text-lg mb-4">
                      Welcome to the Homegrown Tunes platform 🤝🚀
                    </div>
                    <div className="text-gray-500 mb-16 text-[12px]">
                      In order to continue, you have to pay a subscription fee
                      to the platform to support the wonderful artists that
                      keeps the platform lively
                    </div>
                    <div className="">
                      Please press the 'Subscribe' button below 👇 to proceed.
                    </div>
                  </div>
                )}

                {/* Existing user */}
                {profile?.subscriptions > 0 && (
                  <div className="flex flex-col w-full items-center text-center">
                    <div className="text-[50px] mb-8">Oops <span className="text-[70px]">😬</span>,</div>
                    <div className="text-lg mb-4 ">
                      Your subscription has expired <span className="text-[30px]">👀</span>
                    </div>
                    <div className="text-gray-500 mb-16 text-[12px]">
                      In order to continue, you have to pay a subscription fee
                      to the platform to support the wonderful artists that
                      keeps the platform lively
                    </div>
                    <div className="italic">
                      Please press the 'Subscribe' button below 👇 to proceed.
                    </div>
                  </div>
                )}
                <div className="bg-green-500 w-[200px] h-[50px] rounded-lg flex items-center justify-center shadow-md">
                  <PaystackButton className="pay-btn w-full h-full" {...componentProps} />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default RequireSubscribe;
