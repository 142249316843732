import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArtistSidebar from "../../components/Artist/Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/Spinners/Loader/Loader";

const Profile = () => {
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getProfile = async () => {
      try {
        const artistRes = await axiosPrivate.get(
          "/artist/me",
          {},
          {
            signal: controller.signal,
          }
        );
        const artistProfile = artistRes.data?.data?.profile;
        isMounted && setProfile(artistProfile);
      } catch (error) {
        console.error(error);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getProfile();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-green-300 min-h-screen w-full flex flex-col justify-start items-center p-4">
      {profile ? (
        <div className="w-full bg-black text-white h-[700px] rounded flex gap-12 p-4">
          <img
            src={profile?.profileImage}
            className="h-full w-[630px] rounded"
          />
          {/*  */}
          <div className="flex flex-col justify-between mt-6">
            {/*  */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 mb-4">
                <div className="italic text-primary-400 text-sm">
                  Stage Name
                </div>
                <div>{profile?.stageName}</div>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <div className="italic text-primary-400 text-sm">About</div>
                <div>{profile?.about ? profile.about : "-"}</div>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <div className="italic text-primary-400 text-sm">
                  Date Joined
                </div>
                <div>{new Date(profile?.dateJoined).toDateString()}</div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="italic text-primary-400 text-sm">
                  Status
                </div>
                <div className={`${profile?.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>{profile?.status}</div>
              </div>
            </div>

            {/*  */}
            <div className="flex gap-4 text-black">
              {/* <div className="flex flex-col justify-between bg-green-100 rounded-lg min-w-[120px] h-[100px] p-2">
                <div className="italic text-[12px] text-primary-400">Streams</div>
                <div className="text-3xl">50, 291</div>
              </div> */}
              <div className="flex flex-col justify-between bg-green-100 rounded-lg min-w-[120px] h-[100px] p-2">
                <div className="italic text-[12px] text-primary-400">Songs</div>
                <div className="text-3xl">{profile.songsCount}</div>
              </div>
              <div className="flex flex-col justify-between bg-green-100 rounded-lg min-w-[120px] h-[100px] p-2">
                <div className="italic text-[12px] text-primary-400">Followers</div>
                <div className="text-3xl">{profile.followersCount}</div>
              </div>
              {/* <div className="flex flex-col justify-between bg-green-100 rounded-lg min-w-[120px] h-[100px] p-2">
                <div className="italic text-[12px] text-primary-400">Likes</div>
                <div className="text-3xl">{profile.likesCount}</div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const ArtistProfile = ArtistSidebar(Profile);
export default ArtistProfile;
