import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SeqBallSpinner from "../Spinners/SeqBall";

const ArtistSidebar = (Component) =>
  function HOC() {
    const [profile, setProfile] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);

    const navigate = useNavigate();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
      const getProfile = async () => {
        try {
          setLoadingProfile(true);
          const artistRes = await axiosPrivate.get("/artist/me");
          const artistProfile = artistRes.data?.data?.profile;
          setProfile(artistProfile);
        } catch (error) {
          console.error(error);
          //   navigate("/login", { state: { from: location }, replace: true });
        } finally {
          setLoadingProfile(false);
        }
      };

      getProfile();
    }, []);

    return (
      <div className="w-full min-h-[100vh] flex relative">
        <div className="w-[20%] h-screen bg-black text-white fixed top-0 left-0 px-8 pt-4">
          {/* Profile */}
          <div className="h-8 mb-16 flex items-center">
            {!loadingProfile ? (
              <p className="text-green-500">
                {profile?.stageName}
              </p>
            ) : (
              <SeqBallSpinner />
            )}
          </div>

          {/* Browse */}
          <p className="text-xl mb-6 text-gray-500">Browse</p>
          <div className="mb-16 flex flex-col gap-3">
            <div
              onClick={() => navigate("/artist/songs")}
              className="cursor-pointer"
            >
              <span className=""></span>
              <span className="">My Songs</span>
            </div>
          </div>

          {/* Profile */}
          <p className="text-xl mb-6 text-gray-500">Profile</p>
          <div
            onClick={() => navigate("/artist/profile")}
            className="flex flex-col gap-3 mb-4"
          >
            <div className="cursor-pointer">
              <span className=""></span>
              <span className="">View Profile</span>
            </div>
          </div>

          {/* <div onClick={() => navigate("/artist/profile-update")} className="flex flex-col gap-3 mb-4">
          <div className="cursor-pointer">
            <span className=""></span>
            <span className="">Update Profile</span>
          </div>
        </div> */}

          {/* Create  */}
          <button
            onClick={() => navigate("/artist/create")}
            className="text-black bg-white w-full py-3 rounded-2xl mb-12 mt-24"
          >
            Create
          </button>

          <button onClick={() => navigate("/discover")} className="w-full">
            Exit
          </button>
        </div>
        <div className="w-full flex justify-end">
          <div className="bg-green-300 w-[80%] flex justify-start items-start">
            <Component />
          </div>
        </div>
      </div>
    );
  };

export default ArtistSidebar;
