import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Player from "../components/AudioPlayer/Player";
import DisplayDuration from "../components/DisplayDuration";
import Loader from "../components/Spinners/Loader/Loader";
import Sidebar from "../components/Sidebar";
import SongAvatar from "../components/SongAvatar";
import NowPlaying from "../components/Visualizer/NowPlaying";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "axios";

const Songs = () => {
  const [songs, setSongs] = useState(null);
  const [display, setDisplay] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [trackIndex, setTrackIndex] = useState(0);

  const downloadSong = async (song) => {
    try {
      const songUrl = song?.dataUrl;

      const link = document.createElement("a");
      link.download = songUrl;

      link.href = "x";

      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSongs = async () => {
      try {
        const res = await axiosPrivate.get(
          "/song",
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setSongs(res.data.data.songs);
      } catch (error) {
        console.log(error);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getSongs();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-green-200 flex flex-col items-start p-4 w-full min-h-full">
      {songs ? (
        <>
          {songs.length !== 0 ? (
            <>
              <div class="bg-green-300 w-full rounded">
                <table class="w-full text-sm text-left text-gray-500 gap-2">
                  {songs?.map((song, index) => (
                    <tr
                      onClick={() => {
                        setDisplay(true);
                        setTrackIndex(1);
                      }}
                      class="border-b hover:bg-green-500"
                    >
                      <th
                        scope="row"
                        class=" flex items-center px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <SongAvatar style="h-[48px] w-[35px] border-2" coverUrl={song?.coverUrl} />
                        <div className="ml-4">{song.title}</div>
                      </th>
                      <td class="px-6 py-4">{song?.artistId?.stageName}</td>
                      <DisplayDuration audioFile={songs[index].dataUrl} />
                      <td class="">{trackIndex === index && <NowPlaying />}</td>
                      <td>
                        <a
                          href={song?.dataUrl}
                          download={song?.title}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button>
                            <svg
                              class="w-6 h-6 text-gray-800"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                              <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                            </svg>{" "}
                          </button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <Player
                tracks={songs}
                trackIndex={trackIndex}
                setTrackIndex={setTrackIndex}
                display={display}
              />
            </>
          ) : (
            <div className="italic w-full text-center">
              Cannot find any song in the database...
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Sidebar(Songs);
