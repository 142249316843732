import { formatTime } from "../../helpers/format-audio-time";

const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }) => {
  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  return (
    <div className="flex items-center gap-4">
      <span className="text-[12px]">{formatTime(timeProgress)}</span>
      <input
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        onChange={handleProgressChange}
        className="w-[500px] h-[5px] border-sm"
      />
      <span className="text-[12px]">{formatTime(duration)}</span>
    </div>
  );
};

export default ProgressBar;
