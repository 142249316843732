import React from "react";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { toast } from "react-toastify";

const SongAvatar = ({ coverUrl, styles, color }) => {
  return (
    <div
      className={`w-[40px] h-[40px] border-green-200 rounded-full flex justify-center items-center bg-green-900 ${styles}`}
    >
      {coverUrl ? (
        <img src={coverUrl} className="w-full h-full rounded-full" />
      ) : (
        <div className="">
          <BsMusicNoteBeamed color={color || "white"} />
        </div>
      )}
    </div>
  );
};

export default SongAvatar;
