import React, { useEffect, useState } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArtistSidebar from "../../components/Artist/Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const CREATE_SONG_URL = "/song";

const Create = () => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [songTitle, setSongTitle] = useState(null);
  const [songFile, setSongFile] = useState(null);
  const [songCover, setSongCover] = useState(null)
  const [progress, setProgress] = useState(0);
  const [songUrl, setSongUrl] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null)
  const [canSave, setCanSave] = useState(false)

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate()

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!songTitle.trim()) {
      toast.warn("Please enter valid song title");
      return;
    }

    if (!songFile) {
      toast.warn("Please select a valid audio file");
      return;
    }

    if (!songCover) {
      toast.warn("Please select valid cover file");
      return;
    }

    try {
      const artistRes = await axiosPrivate.get("/artist/me");
      const artistProfile = artistRes.data?.data?.profile;

      if (artistProfile?.status !== 'active') {
        toast.warn('Cannot upload song. Your account has been deactivated by the admin')
        return;
      }

      upload([songFile.data, songCover.data], setSongUrl, setCoverUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    if (!e.target.files[0]) {
      setSongFile(null)
      return
    }
    const file = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setSongFile(file);
  };

  const handleCoverFileChange = (e) => {
    if (!e.target.files[0]) {
      setSongCover(null)
      return
    }
    const file = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setSongCover(file);
  };

  const upload = async (files, setSongUrl, setCoverUrl) => {
    setUploading(true);
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      const storageRef = ref(storage, `files/${files[i].name}`);
      const uploadTask = uploadBytesResumable(storageRef, files[i]);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.log(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)            
          switch(i) {
              case 1: 
                setCoverUrl(downloadURL);
                break;
              case 0:
                setSongUrl(downloadURL);
                setUploaded(true)
                setUploading(false)
                setCanSave(true);
                break;
            }
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {});
        }
      );
    }
  };

  useEffect(() => {
    if (canSave) {
      save();
    }
  }, [canSave]);

  const save = async () => {
    if (Boolean(!!songUrl && !!coverUrl && uploaded) === false) {
      toast.warn("Failed to upload song");
      console.log("Invalid song URL or song cover");
      return;
    }

    setLoading(true);
    try {
      const res = await axiosPrivate.post(
        CREATE_SONG_URL,
        JSON.stringify({ title: songTitle, dataUrl: songUrl, coverUrl: coverUrl }),
      );
      if (res?.data?.statusCode === 201) {
        toast.success(res?.data?.message);
        navigate("/artist/songs");
      } else {
        toast.error("Failed to upload song")
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("Failed to upload song")
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-green-300 min-h-screen w-full flex flex-col justify-start items-center py-4">
      <div className="w-[30rem] flex flex-col">
        <form class="space-y-4 md:space-y-6 mt-32" onSubmit={handleFormSubmit}>
          <div>
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-4 outline-none"
              placeholder=""
              value={songTitle}
              onChange={(e) => setSongTitle(e.target.value)}
              required
            />
            
            <label>File (audio file)</label>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-4 outline-none">
              <input type="file" onChange={handleFileChange} />
            </div>

            <label>Cover photo</label>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-4 outline-none">
              <input type="file" onChange={handleCoverFileChange} />
            </div>
          </div>
          <button
            disabled={loading || uploading}
            type="submit"
            class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-primary-300"
          >
            {loading ? (
              <svg
                aria-hidden="true"
                role="status"
                class="inline w-6 h-6 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              ""
            )}
            {uploading ? `Uploading (${progress}%)` : !loading ? "Upload" : ""}
            {loading ? "Creating..." : ""}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ArtistSidebar(Create);
