import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Spinners/Loader/Loader";
import Sidebar from "../components/Sidebar";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { BsMusicNoteBeamed } from "react-icons/bs";
import DisplayDuration from "../components/DisplayDuration";
import PlayIconEmpty from "../components/AudioPlayer/PlayIconEmpty";
import SeqBallSpinner from "../components/Spinners/SeqBall";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import Player from "../components/AudioPlayer/Player";

const Artist = () => {
  const { artistId } = useParams();
  const [artistInfo, setArtistInfo] = useState(null);
  const [artistSongs, setArtistSongs] = useState(null);
  const [artistFollowers, setArtistFollowers] = useState(null);
  const [followed, setFollowed] = useState(null);
  const [loading, setLoading] = useState(false);

  const [display, setDisplay] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const getArtistProfile = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/artist/${artistId}`);
      if (res.data.statusCode === 200) {
        setArtistInfo(res.data?.data?.artist);
        setArtistSongs(res.data?.data?.songs);
        setArtistFollowers(res.data?.data?.followers);
      } else {
        toast.error(res.data?.message);
      }
    } catch (error) {
      console.error(error);
      //   navigate("/login", { state: { from: location }, replace: true });
    } finally {
      setLoading(false);
    }
  };

  const followArtist = async () => {
    try {
      const res = await axiosPrivate.post(`artist/${artistInfo._id}/follow`);
      if (res.data.statusCode == 200) {
        toast.success(res.data?.message);
        getArtistProfile();
      } else {
        toast.success(res.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getArtistProfile();
  }, []);

  return (
    <div className="bg-green-200 flex flex-col items-start p-4 w-full min-h-full">
      {!loading ? (
        <>
          {artistInfo !== null ? (
            <div className="w-full">
              <div className="bg-black text-white rounded-3xl w-[99%] h-[400px] mt-4 mx-1 flex mb-8 px-4 py-3">
                <img
                  className="rounded-3xl h-full w-[350px]"
                  src={artistInfo?.profileImage}
                />
                <div className="flex flex-col pt-10 pl-6">
                  <div className="text-gray-500 text-3xl mb-4">
                    {artistInfo?.stageName}
                  </div>
                  <div className="text-gray-400 mb-10">{artistInfo?.about}</div>
                  <button
                    onClick={followArtist}
                    className="bg-green-500 text-gray-800 w-[200px] h-[50px] rounded-lg flex justify-center items-center"
                  >
                    {artistFollowers.includes(auth?.userId)
                      ? "Unfollow"
                      : "Follow"}
                  </button>
                </div>
              </div>
              <div className="text-lg underline mb-3 ml-12">Songs</div>
              <div className="flex flex-col gap-4 h-[300px] mx-12">
                {artistInfo.songs !== null ? (
                  <>
                    {artistSongs?.map((song, index) => (
                      <div className="bg-black flex justify-between items-center rounded w-full px-4 py-1 h-[50px]">
                        <div className="flex items-center gap-3">
                          <div className="border-green-200 rounded-full w-[40px] h-[40px] flex justify-center items-center bg-green-900">
                            {song?.coverUrl ? (
                              <img
                                src={song?.coverUrl}
                                className="w-full h-full rounded-full"
                              />
                            ) : (
                              <div className="">
                                <span className="">
                                  <BsMusicNoteBeamed color="white" />
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="text-gray-400">{song.title}</div>
                        </div>
                        <div className="flex text-[12px]">
                          <DisplayDuration
                            audioFile={song?.dataUrl}
                            styles="text-gray-400"
                          />
                        </div>
                        <button
                          onClick={() => {
                            setDisplay(true);
                            setTrackIndex(index);
                          }}
                        >
                          <PlayIconEmpty styles="w-5 h-5 text-green-600" />
                        </button>
                      </div>
                    ))}
                    <Player
                      display={display}
                      trackIndex={trackIndex}
                      setTrackIndex={setTrackIndex}
                      tracks={artistSongs}
                    />
                  </>
                ) : (
                  <div className="h-full flex justify-center items-center">
                    <SeqBallSpinner />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>Cannot load artist data</div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Sidebar(Artist);
