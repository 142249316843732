import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArtistSidebar from "../../components/Artist/Sidebar";
import Player from "../../components/AudioPlayer/Player";
import DisplayDuration from "../../components/DisplayDuration";
import Loader from "../../components/Spinners/Loader/Loader";
import SongAvatar from "../../components/SongAvatar";
import NowPlaying from "../../components/Visualizer/NowPlaying";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Songs = () => {
  const [songs, setSongs] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [trackIndex, setTrackIndex] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSongs = async () => {
      try {
        const artistRes = await axiosPrivate.get("/artist/me");
        const artistProfile = artistRes.data?.data?.profile;
        const artistSongsRes = await axiosPrivate.get(
          `/artist/${artistProfile.id}/songs`,
          {},
          {
            signal: controller.signal,
          }
        );
        isMounted && setSongs(artistSongsRes.data?.data?.songs);
      } catch (error) {
        console.error(error);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getSongs();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-green-200 flex flex-col items-start p-4 w-full min-h-full">
      {songs ? (
        <>
          {songs.length !== 0 ? (
            <>
              <div class="bg-green-300 w-full rounded">
                <table class="w-full text-sm text-left text-gray-500 gap-2">
                  {songs?.map((song, index) => (
                    <tr class="border-b hover:bg-green-500">
                      <th
                        scope="row"
                        class=" flex items-center px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <SongAvatar style="h-[48px] w-[35px] border-2" />
                        <div className="ml-4">{song.title}</div>
                      </th>
                      <td class="px-6 py-4">{song?.artistId?.stageName}</td>
                      <DisplayDuration audioFile={songs[index].dataUrl} />
                      <td class="">{trackIndex === index && <NowPlaying />}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <Player
                tracks={songs}
                trackIndex={trackIndex}
                setTrackIndex={setTrackIndex}
              />
            </>
          ) : (
            <div className="italic w-full text-center flex flex-col justify-center items-center">
              Cannot find any song in the database...{" "}
              <button
                onClick={() => navigate("/artist/create")}
                className="text-black bg-white w-[100px] py-3 rounded-2xl mb-12 mt-24"
              >
                Create
              </button>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const ArtistSongs = ArtistSidebar(Songs);
export default ArtistSongs;
